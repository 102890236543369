.root
  position: relative
  &::before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: -18px
    width: 18px
    background: var(--mantine-color-hifivework-5)

.rootAccountant
  position: relative
  &::before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: -18px
    width: 18px
    background: var(--mantine-color-orange-3)
