.root
  border-radius: var(--mantine-spacing-xs)
  padding: var(--mantine-spacing-xs)
  padding-left: var(--mantine-spacing-sm)

.chevron
  stroke: var(--mantine-color-hifivework-5)
  width: calc(1.25rem* var(--mantine-scale)) !important
  height: calc(1.25rem* var(--mantine-scale)) !important

.label
  color: var(--mantine-color-black)

.collapse
  width: 100%

.left
  max-width: calc(99% - 300px)

.menuSticky
  min-width: 300px
  max-width: 300px
  border-left: 1px solid var(--mantine-color-gray-3)
  padding-left: var(--mantine-spacing-sm)
  width: 300px
  top: var(--app-shell-header-offset)
  padding-top: var(--mantine-spacing-lg)
  position: sticky
  height: calc(100dvh - var(--app-shell-header-offset) - 50px)
  overflow: scroll

.header
  margin: -18px
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md)
  position: sticky
  top: calc(3.7rem* var(--mantine-scale))
  background-color: var(--mantine-color-white)
  z-index: 99
  border-bottom: 1px solid var(--mantine-color-gray-3)
