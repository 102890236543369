@use 'src/styles/global' as *

.overviewWrapper
  border-width: 1px
  border-left-width: 0
  border-right-width: 0
  border-style: solid
  padding: var(--mantine-spacing-sm)
  cursor: pointer
  display: flex
  flex-direction: column
  @include scale-on-hover
