.root-sm
  background-color: #fafafa
  min-height: 3.53rem
  max-height: 3.53rem
  min-width: 2.5rem
  max-width: 2.5rem

.iframe-sm
  width: inherit
  height: inherit
  min-height: 3.53rem
  max-height: 3.53rem
  min-width: 2.5rem
  max-width: 2.5rem
  border: 0

.root-md
  min-height: 5.8rem
  max-height: 5.8rem
  min-width: 4.11rem
  max-width: 4.11rem

.iframe-md
  width: inherit
  height: inherit
  min-height: 5.8rem
  max-height: 5.8rem
  min-width: 4.11rem
  max-width: 4.11rem
  border: 0

.root-lg
  background-color: #fafafa
  width: 100%

.iframe-lg
  width: inherit
  height: inherit
  min-height: calc(100vh - 200px)
  max-height: calc(100vh - 200px)
  border: 0
