@use 'src/styles/global' as *

.item
  padding: var(--mantine-spacing-md)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  border-radius: var(--mantine-radius-md)
  background-color: var(--mantine-color-white)
  @include scale-on-hover
